<template>
  <transition name="fade">
    <new-message-mobile v-if="isUnReadMessage" />
  </transition>
  <template v-if="type != 'empty'">
    <div class="fx">
      <ul class="fxhead">
        <li class="atten" :class="{'on': $route.meta.gnbMenu === 'charge'}"><a @click="goPageByName('charge')"><em></em><span>{{$t('front.common.deposit')}}</span></a></li>
        <li class="notice" :class="{'on': $route.meta.gnbMenu === 'exchange'}"><a @click="goPageByName('exchange')"><em></em><span>{{$t('front.common.withdrawal')}}</span></a></li>
        <li class="home" :class="{'on': $route.meta.gnbMenu === 'betList'}"><a @click="goPageByName('betList')"><em></em><span>{{$t('front.mypage.betList')}}</span></a></li>
        <li class="ques" :class="{'on': $route.meta.gnbMenu === 'cs'}"><a @click="goPageByName('qnaList')"><em></em><span>{{$t('front.common.cscenter')}}</span></a></li>
        <li class="my" :class="{'on': $route.meta.gnbMenu === 'myinfo'}"><a @click="goPageByName('myinfo')"><em></em><span>{{$t('front.common.mypage')}}</span></a></li>
        <!--li class="home on"><a @click="goPageByName('mobileMain')"><span>홈</span></a></li-->
      </ul>
    </div>

    <header>
      <!--div class="noticeWrap">
        <div class="notice"><em>{{$t('front.common.notices')}}</em><span>{{$t('front.header.msg')}}</span></div>
        <div class="langbtn" @click="openLang()">
          <button class="langdropbtn" >
            <template v-if="$i18n.locale === 'ko'">
              <img src="@/assets_mobile/img/korea.png" /><span class="langsp">KO</span>
            </template>
            <template v-if="$i18n.locale === 'en'">
              <img src="@/assets/img/eng.png" /><span class="langsp">EN</span>
            </template>
            <template v-if="$i18n.locale === 'vn'">
              <img src="@/assets/img/vn.svg" /><span class="langsp">VN</span>
            </template>
            <img class="langbtnb" src="@/assets_mobile/img/langbtn.png" />
          </button>
          <div class="langdropdown" v-if="isOpenLang">
            <a href="#" :class="{'on': $i18n.locale === 'ko'}" @click="changeLocale('ko')">KR</a>
            <a href="#" :class="{'on': $i18n.locale === 'en'}" @click="changeLocale('en')">EN</a>
            <a href="#" :class="{'on': $i18n.locale === 'vn'}" @click="changeLocale('vn')">VN</a>
          </div>
        </div>
      </div-->

      <div class="header">
        <h1 class="logo"><a @click="goPageByName('mobileMain')"><img src="@/assets/img/play_station.svg" /></a></h1>
        <div class="logininfowrap" v-if="userData">
          <ul class="mym">
            <li class="myc"><img src="@/assets_mobile/img/mycicon.png" /><span>{{userData.cashAmt ? thousand(userData.cashAmt.toString()): 0}}</span></li>
            <li class="myp" @click="goPageByName('point')"><img src="@/assets_mobile/img/mypicon.png" /><span>{{userData.pointAmt ? thousand(userData.pointAmt.toString()): 0}}</span></li>
            <!--li class="myg"><img src="@/assets_mobile/img/mygicon.png" /><span>{{thousand(coinAmt ? coinAmt.toString(): '0')}}</span></li-->
          </ul>
          <a @click="goPageByName('msgMain')" class="mymsg"><img src="@/assets_mobile/img/message_off.svg" /><span class="mgsn">{{thousand(unReadMessageCount ? unReadMessageCount.toString() : '0')}}</span></a>
        </div>
        <div v-if="!userData">
          <a class="mloginbtn" @click="goPageByName('mobileJoin')">JOIN</a>
          <a class="mloginbtn ml10" @click="goPageByName('mobileLogin')">LOGIN</a>
        </div>
      </div>
    </header>
  </template>
</template>

<script>

import { mapState } from 'vuex'
import NewMessageMobile from '@/components/member/MessageMobile.vue'

export default {
  name: 'Header',
  components: { NewMessageMobile },
  props: [
    'type'
  ],
  data () {
    return {
      isOpenLang: false
    }
  },
  computed: {
    ...mapState([
      'userData',
      'unReadMessageCount'
    ]),
    isUnReadMessage () {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  methods: {
    openLang () {
      this.isOpenLang = !this.isOpenLang
    },
    onLogOut () {
      this.$root.signOut()
      this.$router.push({ name: 'main' })
      // location.reload()
    }
  }
}
</script>

<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
